<template>
  <div id="projects-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link v-if="project.projectSelection.length > 1" class="btn btn-primary mb-5" to="/projects/">Zurück zur Übersicht</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Further categorize Items</li>
      <li>Make more Content</li>
      <li>Connect final Items to content and tools</li>
      <li>Generate i18n</li>
    </ul>-->

    <div class="card mb-4">
      <h4 class="card-header">
        <i v-if="project.deleted" class="fad fa-trash"></i>
        <i v-else-if="!projectOwned(project)" class="fad fa-bug"></i>
        {{ project.profile.projectName }}
      </h4>
      <div class="card-body">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3 border-right">
              <button :class="view === 'base' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'base'">Stammdaten</button>
              <button :class="view === 'scraper' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'scraper'">Website-Daten</button>
              <button :class="view === 'members' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'members'">Team</button>
              <button :class="view === 'address' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'address'">Unternehmen</button>
              <button :class="view === 'invite' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'invite'">Einladen</button>
              <button :class="view === 'settings' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'settings'">Einstellungen</button>
            </div>
            <div class="col-sm-9">
              <div v-if="view === 'base'">
                <h4>Stammdaten</h4>
                <div class="form-row">
                  <div class="col-sm-7">
                    <div class="form-group">
                      <label>Projekt-Titel</label>
                      <input v-model="selectedProject.profile.projectName" class="form-control" placeholder="Name des Projekts" type="text">
                    </div>
                    <div class="form-group">
                      <label>Eure Theme-Farbe</label>
                      <input v-model="selectedProject.settings.themeColor" class="form-control" placeholder="Themen-Farbe" type="color">
                    </div>
                    <div class="form-group">
                      <label>Kontakt E-Mail-Adresse</label>
                      <input v-model="selectedProject.profile.contactEmail" class="form-control" placeholder="Kontakt E-Mail-Adresse" type="text">
                    </div>
                    <!--<div class="form-group">
                      <label>Sales E-Mail-Adresse</label>
                      <input v-model="selectedProject.profile.salesEmail" class="form-control" placeholder="Sales E-Mail-Adresse" type="text">
                    </div>-->
                  </div>
                  <div class="col-sm-5 text-center">
                    <div class="avatar-wrapper">
                      <img :src="selectedProject.profile.logo" alt="Profilbild" class="img-fluid avatar">
                    </div>
                    <label>Profilbild</label>

                    <input v-model="selectedProject.profile.logo" class="form-control mb-3" placeholder="URL einfügen" type="text">
                    <p class="text-center small text-muted">oder:</p>
                    <FileUpload @filesSaved="filesSaved($event)" :multiple="false"></FileUpload>
                  </div>
                </div>
              </div>
              <div v-else-if="view === 'scraper'">
                <h4>Website-Daten</h4>
                <div class="form-group">
                  <label>Stamm-URL der Projekt-Webseite</label>
                  <input v-model="selectedProject.profile.rootUrl" class="form-control" placeholder="Projekt-Webseite" type="text">
                </div>
                <h5>Probleme mit den Scraping-Daten?</h5>
                <router-link to="/scraper" class="btn btn-primary">Website neu scrapen</router-link>
              </div>
              <div v-else-if="view === 'address'">
                <h4>Ansprechpartner</h4>
                <div class="form-row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Vorname</label>
                      <input v-model="selectedProject.profile.firstName" class="form-control" placeholder="Vorname" type="text">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Nachname</label>
                      <input v-model="selectedProject.profile.lastName" class="form-control" placeholder="Nachname" type="text">
                    </div>
                  </div>
                </div>
                <hr>
                <h4>Adresse</h4>
                <div class="form-group">
                  <label>Name</label>
                  <input v-model="selectedProject.profile.address.name" class="form-control" placeholder="Name" type="text">
                </div>
                <div class="form-group">
                  <label>Straße und Hausnummer</label>
                  <input v-model="selectedProject.profile.address.street" class="form-control" placeholder="Straße und Hausnummer" type="text">
                </div>
                <div class="form-row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>PLZ</label>
                      <input v-model="selectedProject.profile.address.zipCode" class="form-control" placeholder="PLZ" type="text">
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <div class="form-group">
                      <label>Stadt</label>
                      <input v-model="selectedProject.profile.address.city" class="form-control" placeholder="Stadt" type="text">
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Region</label>
                      <input v-model="selectedProject.profile.address.area" class="form-control" placeholder="Region" type="text">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Land</label>
                      <input v-model="selectedProject.profile.address.country" class="form-control" placeholder="Land" type="text">
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="view === 'members'">
                <h4>Team</h4>
                <div v-for="(member, index) in selectedProject.members" :key="index" class="card mb-2">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-2">
                        <div v-if="member.logo" class="avatar-wrapper">
                          <img :alt="member.email + ' Profilbild'" :src="member.logo" class="img-fluid avatar">
                        </div>
                        <div v-else-if="member.settings" class="color-orb" :style="'background:' + member.settings.chatColor"></div>
                      </div>
                      <div class="col" :class="selectedProject.members.find(m => m.email === user.email) ? 'text-primary' : ''">
                        <h5>{{ member.firstName }} {{ member.lastName }}</h5>
                        <p class="text-muted mb-0">{{ member.email }} / {{ member.telephone }}</p>
                      </div>
                      <div v-if="isProjectAdmin && !selectedProject.members.find(m => m.email === user.email) && !member.roles.indexOf('admin') >= 0"
                           class="col-auto">
                        <button @click="removeMember(member)" class="btn btn-dark">Mitglied entfernen</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="view === 'invite'">
                <h4>Einladungslink für das Projekt</h4>
                <a :href="inviteLink">{{ inviteLink }}</a>
                <hr>
                <h4>Mitglieder per E-Mail einladen</h4>
                <input v-model="inviteEmails" class="form-control mb-3" placeholder="Mehrere E-Mails mit Komma trennen" type="text">
                <p>
                  Aktuell werden noch keine Einladungs-Mails versandt. Bitte benachrichtige deine Team-Mitglieder selbst.
                </p>
                <button class="btn btn-primary" @click="invite">E-Mails einladen</button>
              </div>
              <div v-else-if="view === 'settings'">
                <h4>Einstellungen</h4>
                <hr>

                <!--<h5>Eigene Tags</h5>
                <ul class="">
                  <li v-for="(prop, index) in customAttributes.tags" :key="index">
                    <div class="input-group input-group-sm mb-2">
                      <input type="text" :value="prop" @change="changeProp('tags', $event, index)" class="form-control">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i @click="removeProp('tags', index)" class="fal fa-minus-circle text-danger"></i>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <input type="text" @change="addProp('tags', $event)" class="form-control form-control-sm"
                           placeholder="Neues Attribut hinzufügen..."/>
                  </li>
                </ul>

                <hr>

                <h5>Eigene Plattformen</h5>
                <ul class="">
                  <li v-for="(prop, index) in customAttributes.contentPlatforms" :key="index">
                    <div class="input-group input-group-sm mb-2">
                      <input type="text" :value="prop" @change="changeProp('contentPlatforms', $event, index)" class="form-control">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i @click="removeProp('contentPlatforms', index)" class="fal fa-minus-circle text-danger"></i>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <input type="text" @change="addProp('contentPlatforms', $event)" class="form-control form-control-sm"
                           placeholder="Neues Attribut hinzufügen..."/>
                  </li>
                </ul>

                <hr>-->
                <h5>Seitentypen</h5>
                <ul class="">
                  <li v-for="(prop, index) in customAttributes.nodePagetypes" :key="index">
                    <div class="input-group input-group-sm mb-2">
                      <input type="text" :value="prop.title" @change="changeProp('nodePagetypes', $event, index)" class="form-control">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i @click="removeProp('nodePagetypes', index)" class="fal fa-minus-circle text-danger"></i>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <input type="text" @change="addProp('nodePagetypes', $event)" class="form-control form-control-sm"
                           placeholder="Neuen Seitentypen hinzufügen..."/>
                  </li>
                </ul>

                <hr>

                <h5>Seiten-Metriken:</h5>
                <ul class="">
                  <li v-for="(prop, index) in customAttributes.nodeMetrics" :key="index">
                    <div class="input-group input-group-sm mb-2">
                      <input type="text" :value="prop.title" @change="changeProp('nodeMetrics', $event, index)" class="form-control">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i @click="removeProp('nodeMetrics', index)" class="fal fa-minus-circle text-danger"></i>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <input type="text" @change="addProp('nodeMetrics', $event)" class="form-control form-control-sm"
                           placeholder="Neue Metrik hinzufügen..."/>
                  </li>
                </ul>

                <hr>

                <h5>Seiten-Attribute</h5>
                <ul class="">
                  <li v-for="(prop, index) in customAttributes.nodeAttributes" :key="index">
                    <div class="input-group input-group-sm mb-2">
                      <input type="text" :value="prop.title" @change="changeProp('nodeAttributes', $event, index)" class="form-control">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i @click="removeProp('nodeAttributes', index)" class="fal fa-minus-circle text-danger"></i>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="mt-3">
                    <input type="text" @change="addProp('nodeAttributes', $event)" class="form-control form-control-sm"
                           placeholder="Neues Attribut hinzufügen..."/>
                  </li>
                </ul>

                <hr>

                <button @click="resetNotifications" class="btn btn-primary">Benachrichtigungen zurücksetzen</button>

                <hr>

                <button class="btn btn-danger btn-sm mt-3" @click="removeProject" :disabled="removeStep > 0">Projekt löschen</button>
                <button v-if="removeStep > 0" class="btn btn-danger btn-sm mt-3" @click="removeProject">Sicher?</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row justify-space-between align-items-center">
          <div class="col-auto mr-0">
            <button class="btn btn-primary mr-3" @click="saveAll">Projekt-Daten updaten</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import FileUpload from "@/components/FileUpload";
export default {
  name: 'ProjectsEdit',
  components: {FileUpload},
  data() {
    return {
      loading: false,
      view: 'base',
      inviteEmails: "",
      selectedProjectId: "",
      inviteLink: "",
      newPagetype: {
        id: this.generateId(),
        title: ""
      },
      newMetric: {
        id: this.generateId(),
        title: ""
      },
      newAttribute: {
        id: this.generateId(),
        title: ""
      },
      removeStep: 0
    }
  },
  computed: {
    selectedProject() {
      return {
        profile: this.project.profile,
        settings: this.project.settings,
        members: this.project.members,
      }
    },
    customAttributes() {
      return {
        nodeAttributes: this.project.settings.nodeAttributes,
        nodeMetrics: this.project.settings.nodeMetrics,
        /*pageTypes: this.project.settings.pageTypes,
        tags: this.project.settings.tags,
        topics: this.project.settings.topics,
        contentPlatforms: this.project.settings.contentPlatforms,*/
      }
    },
    isProjectAdmin() {
      let currentMember = this.selectedProject.members.find(m => m.email === this.user.email);
      return currentMember ? currentMember.roles.indexOf('admin') >= 0 : this.isAdmin;
    },
  },
  methods: {
    filesSaved($event) {
      let files = JSON.parse(JSON.stringify($event));
      this.$store.dispatch('upload/uploadFiles', { files, path: "project-img/" + this.project._id })
          .then(res => {
            console.debug(res);
            this.project.profile.logo = res.files[0];
            this.save('profile', this.project.profile);
          });
    },
    invite() {
      let newMembers = this.inviteEmails.split(",").map(m => {
        return {
          email: m.trim()
        }
      });
      let members = this.selectedProject.members.concat(newMembers);
      this.save('members', members);
    },
    addProp(prop, $event) {
      this.project.settings[prop].push($event.target.value);
      this.save('settings', this.project.settings);
      $event.target.value = "";
    },
    removeProp(prop, index) {
      if (confirm("Attribut wirklich löschen? Alle Verknüpfungen gehen dabei verloren.")) {
        this.project.settings[prop].splice(index, 1);
        this.save('settings', this.project.settings);
      }
    },
    changeProp(prop, $event, index) {
      this.project.settings[prop].splice(index, 1, $event.target.value);
      this.save('settings', this.project.settings);
    },
    removeMember(member) {
      if (confirm(member.email + " wirklich aus dem projekt entfernen?")) {
        let index = this.selectedProject.members.findIndex(m => m.email === member.email);
        this.selectedProject.members.splice(index, 1);
        this.save('members', this.selectedProject.members);
      }
    },
    save(prop, data) {
      this.$store.dispatch('project/updateProjectByProp', {prop, data: data || this.project[prop]});
    },
    saveAll() {
      this.save('profile', this.selectedProject.profile);
      this.save('settings', this.selectedProject.settings);
      this.save('members', this.selectedProject.members);
      this.$router.push("/projects");
    },
    removeProject() {
      if (this.removeStep === 0) {
        this.removeStep++;
      } else {
        this.$store.dispatch('project/removeProject')
            .then(res => {
              console.debug(res);
              this.$router.push("/projects");
            });
      }
    },
    resetNotifications() {
      this.$store.dispatch('project/updateProjectByProp', {prop: "notifications", data: {
          profile: "",
          guide: "",
          brainstorm: "",
          canvas: "",
          personas: "",
          jobs: "",
          scraper: "",
          links: "",
          contents: "",
          journeys: "",
          campaigns: "–",
          channels: "–",
          kpis: "–",
          benchmarks: "–",
          audits: "",
          findings: "",
          ideas: "",
          roadmaps: "",
          experiments: "",
          calculations: "",
          todos: "",
          notes: "",
          patterns: "",
          practices: "",
        }});
    },
  },
  beforeMount() {
    this.loading = true;
    if (this.$route.params.id) {
      this.selectedProjectId = this.$route.params.id;
      this.inviteLink = process.env.VUE_APP_API_PROTOCOL + '//' + process.env.VUE_APP_API_HOST + '/signup/' + this.selectedProjectId;
    }
  }
}
</script>

<style lang="scss" scoped>
.color-orb {
  border-radius: 100%;
  height: 0;
  width: 100%;
  padding-top: 100%;
}
.avatar-wrapper {
  width: 100%;
  padding-top: 100%;
  margin: 0;
}
</style>
